.Reports {
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    .StepContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 30px 0 0;
      .OptionsBox {
        max-width: 500px;
      }
      .ReportList {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          & + li {
            margin-top: 15px;
          }
          > label {
            display: block;
            position: relative;
            padding-left: 30px;
            .ant-radio-wrapper {
              position: absolute;
              left: 0;
            }
            span {
              display: block;
            }
            strong {
              display: block;
              margin-bottom: 3px;
            }
          }
        }
      }
      &Buttons {
        margin-top: auto;
        display: flex;
        .Next {
          margin-left: auto;
        }
      }
    }
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;