.CaseYBM {
  .ant-pagination {
    margin-bottom: 0;
  }
}

.YBMFeedbackEntry {
  text-align: center;
  border: solid 1px #CCC;
  border-radius: 4px;
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  height: calc(100% - 16px);

  > strong {
    display: block;
  }

  > small {
    display: block;
    font-style: italic;
  }

  > span {
    display: block;
    font-size: 80%;
    margin-bottom: 10px;
  }

  .ant-space {
    justify-content: center;
    margin-top: auto;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;