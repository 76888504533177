.CaseComplaint {
  .ant-pagination {
    margin-bottom: 0;
  }
}

.Text-Green {
  color: green !important;
}
.Text-Red {
  color: red !important;
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;