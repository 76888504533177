.async_confirm {
  .confirm_btns {
    text-align: right;
    margin-top: 32px;

    & > button {
      margin: 0 4px;
    }
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;