@import '~antd/dist/antd.less';

html, body, #root {
  height: 100%;
}

body {
  background-color: #efefef;
}

.LinkButton {
  background-color: transparent;
  border: none;
  color: #1890ff;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.CaseTabs {
  height: 100%;

  > .ant-tabs-nav {
    margin: 0 !important;

    &:before {
      border-bottom-color: #CCC !important;
    }

    .ant-tabs-tab {
      border-color: #CCC !important;

      &.ant-tabs-tab-active {
        border-bottom-color: #FFF !important;
      }
    }
  }

  > .ant-tabs-content-holder {
    background-color: #FFF;
    border: solid 1px #CCC;
    border-width: 0 1px 1px;

    > .ant-tabs-content {
      height: 100%;
      padding: 15px;

      > .ant-tabs-tabpane {
        overflow-y: auto;
      }
    }
  }
}

.clickable {
  color: @link-color;
  cursor: pointer;
  text-decoration: underline;
}

.ant-form-item {
  &:not(.no-margin) {
    margin-bottom: 15px !important;
  }

  &.no-margin {
    margin-bottom: 0 !important;
  }
}


.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 90% !important;

    @media screen and (min-width: @screen-lg-max) {
      width: 50% !important;
    }
  }
}

.PaginationButton {
  display: block;
  margin-top: -32px;
}

.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}

@media screen and (max-width: @screen-md) {
  .ant-page-header-heading-extra {
    .ant-btn {
      padding: 2.4px 0;
      width: 32px;

      span:not(.anticon) {
        display: none;
      }
    }
  }
}


.TrafficLight {
  background-color: #efefef;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;

  &.Is-Red {
    background-color: @theme-red;
  }

  &.Is-Amber {
    background-color: orange;
  }

  &.Is-Green {
    background-color: limegreen;
  }
}


.Feedback-Status-5 {
  color: #008000;
  font-weight: bold;
}

.Feedback-Status-4 {
  color: #00C100;
}

.Feedback-Status-3 {
  color: #C94800;
}

.Feedback-Status-2 {
  color: #FF4E4E;
}

.Feedback-Status-1 {
  color: #EE000E;
  font-weight: bold;
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;