@import '~antd/lib/style/themes/default';

.Master {
  height: 100%;

  .ant-layout-sider {
    background: @theme-dark-red;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    .MenuWrapper {
      height: auto;
      overflow: auto;
    }

    .ant-menu-root {
      background: darken(@theme-dark-red, 5%);
    }

    .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .ant-menu-submenu-active {
      .ant-menu-submenu-title {
        .anticon {
          color: #FFF;
        }
      }
    }
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 15px;
    cursor: pointer;
  }

  .logo {
    height: 34px;
    margin: 15px;
    display: block;

    svg {
      transition: height 150ms, margin-top 150ms;
      height: 32px;
      width: auto;
    }
  }

  .ant-menu-root {
    .ant-menu-sub {
      background: rgba(#FFFFFF, 0.1) !important;

      .ant-menu-item-selected {
        background: rgba(#FFFFFF, 0.2);

        &:after {
          border-right: solid 3px #FFFFFF;
        }
      }
    }
  }

  .ant-layout-sider-collapsed {
    .logo {
      svg {
        height: 25px;
        margin-top: 3px;
      }
    }
  }

  .ant-layout-header {
    background: #fff;
    padding: 0;
    display: flex;

    .anticon {
      transition: color 150ms;

      &:hover {
        color: @theme-red-hover;
      }
    }

    > div {
      margin-left: auto;
      margin-right: 15px;

      .anticon {
        font-size: 18px;
        margin-left: 15px;
      }
    }
  }

  .ant-layout-content {
    margin: 15px;
    padding: 15px;
    background: #fff;
    //min-height: 280px;
  }

  .ant-page-header {
    border-bottom: solid 2px @theme-dark-red;
    padding: 0 0 5px;
    margin-bottom: 20px;

    &.Secondary {
      margin-top: 15px;
      border-bottom-color: #CCC;
      border-bottom-width: 1px;

      .ant-page-header-heading-title {
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
}

.ant-drawer {
  &.NotificationDrawer {
    .ant-drawer-content-wrapper {
      max-width: 300px !important;
      width: 100% !important;
    }

    .NotificationList {
      max-height: unset;
    }
  }
}

.NotificationHeader {
  display: flex;
  align-items: center;

  .clickable {
    margin-left: auto;
    font-size: 12px;
    font-weight: 300;
  }
}

.NotificationPopover {
  .NotificationList {
    width: 300px;
  }
}

.NotificationList {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 300px;
  max-height: 150px;
  overflow: auto;

  .Notification {
    & + .Notification {
      margin-top: 8px;
      padding-top: 8px;
      border-top: solid 1px #eaeaea;
    }

    font-size: 12px;

    .NotificationTitle {
      display: flex;
      align-items: center;

      span {
        display: block;
        cursor: pointer;
        color: @primary-color;
      }

      .Remove {
        margin-left: auto;
        color: #999;

        &:hover {
          color: @theme-red-hover;
        }
      }
    }

    small {
      display: block;
      font-size: 10px;
      color: #666;
    }

    &.Unread {
      span {
        font-weight: 700;
      }
    }
  }
}

.AppVersion {
  text-align: center;
  display: block;
  padding: 6px;
  color: rgba(255,255,255, 0.5);
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;