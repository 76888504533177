.Auth {
  max-width: 330px;
  margin: 30px auto 0;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 10px 20px 10px;
  box-shadow: 0 0 10px rgba(#000000, 0.1);

  header {
    text-align: center;

    svg {
      margin-bottom: 10px;
    }
  }

  .ant-alert {
    margin-bottom: 15px;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;