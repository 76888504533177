@import '~antd/dist/antd.less';

.Mail-Inbox {
  .ant-layout-content {
    background: unset;
    padding: 0;
  }
}

#ProcessMail:not(:disabled) {
  .button-variant-primary(#FFFFFF, @primary-6);
}

.MailViewButtons {
  display: flex;
  overflow: hidden;
  transition: max-height 0.15s ease-in;

  &.Hide {
    max-height: 0;
    transition: max-height 0.15s ease-out;
  }
}

.ant-drawer {
  &.MailDrawer {
    .ant-drawer-content-wrapper {
      @media screen and (min-width: @screen-lg-max) {
        width: 90% !important;
      }
    }
  }
}

.MailTable {
  .ant-table-tbody {
    .idx-0 td {}
    .idx-1 td { background-color: #F6F9FE; }
  }
}

.MailReplyForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .ant-form {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item {
    flex: 1;
  }
  .ant-form, .ant-form-item-control-input, .ant-form-item-control-input-content, textarea {
    height: 100% !important;
  }

  .ant-upload-list-text-container {
    margin-top: -8px;
    margin-bottom: 15px;
  }
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.MailComposeForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .FullHeight {
    flex: 1;
    .ant-form-item-control-input, .ant-form-item-control-input-content, textarea {
      height: 100% !important;
    }
  }
  .ant-upload-list-text-container {
    .ant-upload-list-item {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.MailTable {
  strong {
    font-weight: 500;
  }
}

.MailHeader {
  font-size: 14px !important;
  font-weight: 400;

  strong {
    display: inline-block;
    width: 100px;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;