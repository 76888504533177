.CaseTableActions {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .CaseTableTitle {
    margin-right: auto;
    font-size: 1.2rem;
    font-weight: 500;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;