.CaseTabs {
  .ant-pagination {
    margin-bottom: 0 !important;
  }
}

.ant-select-selection-item-content {
  span {
    display: none;
  }
  small {
    font-size: 14px !important;
  }
}
@theme-red: #ff3108;@theme-red-hover: #d42300;@theme-dark-red: #8C0000;@btn-primary-bg: @theme-red;@zindex-notification: 1031;